import React, { useEffect, useState } from "react";
import { S3_ENDPOINT } from "../../utils";

const FileAnswer = ({ user_answers, setAnswer, question }) => {
  const [spinner, setSpinner] = useState(false);
  const [useLink, setUseLink] = useState(false);
  const [inputReady, setInputReady] = useState(false);
  const [currentAnswer, setCurrentAnswer] = useState(null);

  useEffect(() => {
    setInputReady(false);
    setTimeout(() => {
      setInputReady(true);
    }, 500);
  }, [question]);

  useEffect(() => {
    handleCurrentAnswer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, user_answers]);

  const getValue = () => {
    if (hasCurrentAnswer()) {
      return currentAnswer.content;
    }
    return question.answer;
  };

  const hasCurrentAnswer = () => currentAnswer && currentAnswer !== null;

  const handleCurrentAnswer = () => {
    if (Array.isArray(user_answers) && !spinner) {
      let current = user_answers.find(
        (answer) =>
          parseInt(answer.question_id) === parseInt(question.question_id)
      );
      if (!current && current === null) {
        return setCurrentAnswer({});
      }
      if (question.type === "file") {
        setSpinner(true);
        setCurrentAnswer(null);
        setTimeout(() => {
          setSpinner(false);
          setCurrentAnswer(current);
        }, 1500);
      } else {
        setCurrentAnswer(current);
      }
    }
  };

  const renderValue = () => {
    if (spinner) {
      return (
        <span className="d-block mb-3">
          <div className="spinner-border small me-2" />
          Cargando video...
        </span>
      );
    }
    if (hasCurrentAnswer()) {
      if (String(currentAnswer.content).includes(S3_ENDPOINT)) {
        return (
          <div className="mb-3">
            <label>Video Cargado</label>
            <video className="mw-100 w-100" style={{ maxHeight: 300 }} controls>
              <source src={currentAnswer.content} type="video/mp4" />
            </video>
          </div>
        );
      }
    }
  };

  const renderInput = () => {
    if (hasCurrentAnswer() && !inputReady) {
      return (
        <button
          className="btn btn-outline-dark"
          onClick={() => setInputReady(true)}
        >
          Cambiar Archivo
        </button>
      );
    }
    if (!inputReady) return <div className="spinner-border"></div>;
    if (useLink) {
      return (
        <div>
          <label className="bold">Enlace</label>
          <input
            type="text"
            value={getValue()}
            className="form-control mb-3"
            placeholder="Copia y pega el enlace aquí"
            onChange={(e) => setAnswer(question.question_id, e.target.value)}
          />
        </div>
      );
    }
    return (
      <input
        type="file"
        accept="video/*"
        className="form-control mb-3"
        onChange={(e) => setAnswer(question.question_id, e.target.files[0])}
      />
    );
  };

  const renderInfo = () => {
    if (useLink) {
      return (
        <div className="mb-3">
          <p className="mb-2">
            Para Google Drive, asegúrate que compartas el link con la
            configuración "Cualquier persona con el enlace puede Ver"
          </p>
          <p className="mb-2">
            Para Dropbox, asegúrate que compartas el link con la configuración
            "público"
          </p>
          <p className="mb-2">
            Para YouTube, asegúrate que compartas el link con la configuración
            "No listado" o "Unlisted".
          </p>
        </div>
      );
    }
    return (
      <div className="mb-3">
        <p className="mb-0">
          Tamaño máximo de archivo: 1 GB (1000 MB).{" "}
          <span className="text-danger">
            Haz en click en "Guardar" después de elegir el archivo.
          </span>
        </p>
        <p className="mb-0">
          El video puede tardarse varios minutos en subirse.
        </p>
      </div>
    );
  };

  const renderButton = () => {
    if (useLink) {
      return (
        <p className="bold">
          Copia y Pega un Enlace{" "}
          <button
            onClick={() => setUseLink(false)}
            className="btn ms-3 btn-sm px-0 py-0 btn-link"
          >
            ¿Quieres subir un archivo?
          </button>
        </p>
      );
    }
    return (
      <p className="bold">
        Sube tu Archivo{" "}
        <button
          onClick={() => setUseLink(true)}
          className="btn ms-3 btn-sm px-0 py-0 btn-link"
        >
          ¿Quieres subir un enlace?
        </button>
      </p>
    );
  };

  return (
    <div>
      {renderValue()}
      {renderButton()}
      <h5>Instrucciones Importantes</h5>
      {renderInfo()}
      {renderInput()}
    </div>
  );
};

export default FileAnswer;
