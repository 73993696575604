import React, { createContext, useReducer, useContext } from "react";
import { QUESTIONS_RECEIVED, SET_QUESTION } from "../types/questions";
import {
  ATTEMPTS_RECEIVED,
  SET_SINGLE_ATTEMPT,
  SET_UPDATE,
} from "../types/attemps";
import { ANSWERS_RECEIVED, SET_ANSWER } from "../types/answers";
import AdjuntosService from "../services/AdjuntosService";
import AttemptsReducer from "../reducers/AttempsReducer";
import AttemptsService from "../services/AttemptsService";
import { SINGLE_TEST_RECEIVED } from "../types/tests";
import { ModalContext } from "./ModalContext";
import { navigate } from "@reach/router";
import { S3_ENDPOINT } from "../utils";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  update: false,
  attempt: null,
  spinner: false,
  attempts: null,
  question: null,
  questions: null,
};

export const AttempsContext = createContext(initialState);

export const AttemptsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AttemptsReducer, initialState);

  const { clearModal, success } = useContext(ModalContext);

  const getUserTestAttempts = (test_id) => {
    AttemptsService.getTestAttempts(test_id).then((res) => {
      const { attempts } = res.data;
      dispatch({ type: ATTEMPTS_RECEIVED, payload: attempts });
    });
  };

  const getSingleAttempt = (attempt_id) => {
    AttemptsService.getSingleAttempt(attempt_id).then((res) => {
      const { attempt } = res.data;
      const { test, user_answers } = attempt;
      const { questions } = test;
      dispatch({ type: QUESTIONS_RECEIVED, payload: questions });
      dispatch({ type: SINGLE_TEST_RECEIVED, payload: test });
      dispatch({ type: ANSWERS_RECEIVED, payload: user_answers });
      dispatch({ type: SET_SINGLE_ATTEMPT, payload: attempt });
    });
  };

  const createAttempt = (test_id, showSuccess = true) => {
    AttemptsService.postAttempt(test_id).then((res) => {
      const { attempt } = res.data;
      if (showSuccess) {
        success("Intento iniciado.");
        navigate(`/mytbm/attempt/${attempt.attempt_id}`);
      }
      clearModal();
    });
  };

  const finishAttempt = (attempt_id, user_answers) => {
    const answersToUpload = user_answers.filter(
      ({ user_answer_id }) => !user_answer_id
    );
    const promises = [];
    answersToUpload.forEach((currentAnswer) => {
      promises.push(
        AttemptsService.postUserAnswer({ ...currentAnswer, attempt_id })
      );
    });
    Promise.all(promises).then(() => {
      AttemptsService.finishAttempt(attempt_id).then(() => {
        getSingleAttempt(attempt_id);
        success("Intento terminado");
        clearModal();
      });
    });
  };

  const saveUserAnswer = (user_answer, callback) => {
    dispatch({ type: SHOW_SPINNER });

    const handleSuccess = () => {
      console.log("success");
      console.log(callback);
      success("Respuesta guardada.");
      dispatch({ type: HIDE_SPINNER });
      dispatch({ type: SET_UPDATE, payload: true });
      if (typeof callback === "function") {
        callback();
      }
    };

    const handleError = (error) => {
      alert(error);
      dispatch({ type: HIDE_SPINNER });
    };

    let service = AttemptsService.putUserAnswer;
    if (isNaN(parseInt(user_answer.user_answer_id))) {
      service = AttemptsService.postUserAnswer;
    }

    if (typeof user_answer.content === "object") {
      const formData = new FormData();
      formData.append("file", user_answer.content);
      AdjuntosService.postAdjunto(formData).then((res) => {
        const { file } = res.data;
        user_answer.content = `${S3_ENDPOINT}/${file.name}.${file.type}`;
        service(user_answer).then(handleSuccess).catch(handleError);
      });
    } else {
      service(user_answer).then(handleSuccess).catch(handleError);
    }
  };

  const setQuestion = (question) => {
    dispatch({ type: SET_QUESTION, payload: question });
  };

  const setAnswer = (question_id, content) => {
    dispatch({ type: SET_ANSWER, payload: { question_id, content } });
  };

  const setUpdate = (update) => {
    dispatch({ type: SET_UPDATE, payload: update });
  };

  const clearAttempt = () => {
    dispatch({ type: SET_QUESTION, payload: null });
    dispatch({ type: ANSWERS_RECEIVED, payload: null });
    dispatch({ type: QUESTIONS_RECEIVED, payload: null });
    dispatch({ type: SET_SINGLE_ATTEMPT, payload: null });
    dispatch({ type: SINGLE_TEST_RECEIVED, payload: null });
  };

  const saveAttempt = (attempt, message) => {
    AttemptsService.putAttempt(attempt).then(() => {
      getSingleAttempt(attempt.attempt_id);
      success(message && message !== null ? message : "Intento guardado.");
    });
  };

  return (
    <AttempsContext.Provider
      value={{
        ...state,
        getUserTestAttempts,
        getSingleAttempt,
        saveUserAnswer,
        finishAttempt,
        createAttempt,
        clearAttempt,
        setQuestion,
        saveAttempt,
        setAnswer,
        setUpdate,
      }}
    >
      {children}
    </AttempsContext.Provider>
  );
};
