import React, { useContext, useEffect } from "react";
import { ProductsContext } from "../../context/ProductsContext";
import ProductCard from "../products/ProductCard";

const GridCourses = ({ view, mode, location }) => {
  const { products, getProducts } = useContext(ProductsContext);

  useEffect(() => {
    getProducts({
      mode,
      location,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, location]);

  const renderPrograms = () => {
    if (Array.isArray(products)) {
      let productsRender = [...products];
      if (view === "new") {
        productsRender = productsRender.filter((product) => {
          let prerequisites = 0;
          const courses = product.course_products.length;
          const { course_products } = product;
          course_products.forEach(({ course }) => {
            if (course && course !== null) {
              if (course.prerequisites.length > 0) prerequisites++;
            }
          });
          return courses > prerequisites || prerequisites === 0;
        });
      } else if (view === "certified") {
        productsRender = productsRender.filter(
          (product) =>
            !product.course_products.find(({ course_id }) => course_id === 1)
        );
      }
      productsRender = productsRender.sort((a,b) => a.price > b.price ? -1 : 1);
      return productsRender.map((product) => (
        <div key={product.product_id} className="col-12 col-xl-6 my-5">
          <ProductCard mode={mode} product={product} location={location} />
        </div>
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="row justify-content-center">
      <h3 className="text-center">
        Certificaciones para Alumnas{" "}
        {view === "new" ? "Nuevas" : "Certificadas"}
      </h3>
      {renderPrograms()}
    </div>
  );
};

export default GridCourses;
